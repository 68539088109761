.container

.header
  display: flex
  justify-content: space-after
  padding: 0 20px
  align-items: center
  height: 80px
  h2
    margin: 0 10px 0 0
    width: 18%
    min-width: 215px
  \:global
    .h-SearchInput
      min-width: 270px
      margin-left: 5px

.body
  display: flex
  padding: 20px

.sidebar
  flex-shrink: 0
  width: 18%
  min-width: 210px
  padding-right: 10px

@media only screen and (max-width: 1200px)
  .header
    padding: 0px
  .body
    padding: 20px 0px
  .sidebar
    width: 15%
    min-width: 160px

.otherAppsCallout
  margin-left: 10px
  margin-right: 10px
  color: #14ACE4
  a
    text-decoration: underline

.appList
  flex-grow: 1
  margin: 0 0 0 15px
  min-height: 600px

.emptyAppList
  display: flex
  flex-direction: column
  flex-grow: 1
  justify-content: center
  align-items: center
  margin: 0 0 0 15px
  padding: 20px
  background: #F6F9FB
  font-size: 17px
  \:global
    .bp5-icon
      margin: 15px
      color: #ddd
  .info
    font-size: .8em

.appRow
  display: flex
  justify-content: space-between
  margin: 0
  &.left
    justify-content: flex-start
